<template>
  <section>
    <div class="banner">Blog Atheva</div>
    <div class="container">
      <div class="content">
        <div class="posts-container">
          <BlogPostPreview
            v-for="post in posts"
            :key="post.id"
            v-bind:post="post"
          />
          <div
            v-if="posts.length"
            v-observe-visibility="handleScrolledToBottom"
          ></div>
          <div v-if="!totalPosts" class="not-found">
            <p>Nenhum post encontrado, por favor refine sua busca</p>
          </div>
        </div>
        <BlogPageMenu
          @search-submitted="onSearchSubmited"
          @tag-submitted="onTagSearch"
          @category-submitted="onCategorySearch"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BlogPostPreview from "@/components/BlogPostPreview";
import BlogPageMenu from "@/components/BlogPageMenu";

import { api } from "@/services.js";

export default {
  name: "Home",
  data() {
    return {
      posts: [],
      totalPosts: null,
      currentSearch: "",
      currentTag: "",
      currentCategory: "",
      currentPage: 1,
    };
  },
  computed: {
    lastPage: function () {
      return Math.ceil(this.totalPosts / 10);
    },
  },
  methods: {
    async getRecentPosts() {
      api
        .get("/posts/all", {
          params: {
            platform: "atheva_web",
            limit: 10,
            search_mask: this.currentSearch,
            tag: this.currentTag,
            category: this.currentCategory,
            page: this.currentPage,
          },
        })
        .then((response) => {
          this.posts = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getTotalPosts() {
      api
        .get("/posts/all", {
          params: {
            platform: "atheva_web",
            search_mask: this.currentSearch,
            tag: this.currentTag,
            category: this.currentCategory,
          },
        })
        .then((response) => {
          this.totalPosts = response.data.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSearchSubmited(search) {
      this.currentPage = 1;
      this.currentTag = "";
      this.currentCategory = "";
      this.currentSearch = search;
      this.getRecentPosts();
      this.getTotalPosts();
      this.scrollToTop();
    },
    onTagSearch(tag) {
      this.currentPage = 1;
      this.currentTag = tag;
      this.currentCategory = "";
      this.currentSearch = "";
      this.getTotalPosts();
      this.getRecentPosts();
      this.scrollToTop();
    },
    onCategorySearch(category) {
      this.currentPage = 1;
      this.currentTag = "";
      this.currentCategory = category;
      this.currentSearch = "";
      this.getTotalPosts();
      this.getRecentPosts();
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleScrolledToBottom(isVisible) {
      if (!isVisible) {
        return;
      }
      if (this.currentPage !== this.lastPage) {
        this.currentPage++;

        api
          .get("/posts/all", {
            params: {
              platform: "atheva_web",
              limit: 10,
              search_mask: this.currentSearch,
              tag: this.currentTag,
              category: this.currentCategory,
              page: this.currentPage,
            },
          })
          .then((response) => {
            this.posts.push(...response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.getRecentPosts();
    this.getTotalPosts();
  },
  components: {
    BlogPostPreview,
    BlogPageMenu,
  },
};
</script>

<style scoped>
.banner {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 2rem;
  width: vw;
  color: white;
  background-color: #232f3e;
}
.container {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
}

.posts-container {
  display: flex;
  flex-direction: column;
}

.posts-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found {
  width: 700px;
  padding: 2rem;
}

@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }
}
</style>


