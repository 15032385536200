<template>
  <section class="container">
    <div class="info search">
      <span>Buscar posts</span>
      <div class="post-search">
        <input
          v-model="search"
          v-on:keyup.enter="searchSubmit"
          @submit.prevent="searchSubmit"
          type="text"
          placeholder="Busca por título / autor"
        />
        <img
          @click="searchSubmit"
          class="search-icon"
          src="@/assets/icons/search.svg"
          alt="author"
        />
      </div>
    </div>
    <div class="info recent-posts">
      <span>Posts Recentes</span>
      <div class="recent-posts" v-for="post in recentPosts" :key="post.id">
        <div class="recent-post">
          <img src="@/assets/icons/chevron.svg" />
          <router-link :to="`/blog-post/${post.id}`">
            {{ post.title }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="info">
      <span>Tags</span>
      <div class="info-content">
        <button
          class="tag-button"
          v-for="tag in tags"
          :key="tag"
          @click="tagSubmit(tag)"
        >
          {{ tag }}
        </button>
      </div>
    </div>
    <div class="info">
      <span>Categorias</span>
      <div class="info-content">
        <button
          class="tag-button"
          v-for="category in categories"
          :key="category.id"
          @click="categorySubmit(category.name)"
        >
          {{ category.name }}
        </button>
      </div>
    </div>
  </section>
</template>


<script>
import { api } from "@/services.js";

export default {
  name: "BlogPageMenu",
  data() {
    return {
      search: null,
      posts: [],
      category: null,
    };
  },
  computed: {
    tags: function () {
      if (this.posts.length) {
        return [
          ...new Set(
            this.posts
              .map((post) => [...post.tags])
              .reduce((prev, current) => [...prev, ...current])
          ),
        ];
      } else {
        return [];
      }
    },
    categories: function () {
      return [].concat
        .apply(
          [],
          this.posts.map((post) =>
            post.categories.map((category) => ({
              id: category.id,
              name: category.name,
            }))
          )
        )
        .filter(
          (v, i, a) =>
            a.findIndex((t) => ["id", "name"].every((k) => t[k] === v[k])) === i
        );
    },
    recentPosts: function () {
      return this.posts.slice(0, 3);
    },
  },
  methods: {
    getRecentPosts() {
      api
        .get("/posts/all", { params: { platform: "atheva_web", limit: 10 } })
        .then((response) => {
          this.posts = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchSubmit() {
      this.$emit("search-submitted", this.search);
      this.search = null;
    },
    tagSubmit(tag) {
      this.$emit("tag-submitted", tag);
    },
    categorySubmit(category) {
      this.$emit("category-submitted", category);
    },
  },
  mounted() {
    this.getRecentPosts();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-self: flex-start;
  border-radius: 5px;
  margin-left: 20px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: #f6f3ee;
  padding: 30px 25px;
  border-radius: 3px;
}

.info span {
  margin-bottom: 1rem;
  font-weight: bold;
}

.info span::after {
  content: "";
  width: 40px;
  height: 4px;
  border-radius: 4px;
  background: #f9b010;
  display: block;
  margin: 10px 0;
}

.info-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.info.search {
  margin-top: 0;
}

.post-search {
  position: relative;
  margin-top: 15px;
}

.post-search input {
  margin-bottom: 0;
}

.search-icon {
  position: absolute;
  right: 12px;
  top: 12px;
}

.recent-posts {
  display: flex;
}

.recent-post {
  display: flex;
  margin-bottom: 0.5rem;
}

.recent-post a {
  margin-left: 0.5rem;
}

.tag-button {
  background-color: #232f3e;
  color: #f9b010;
  font-size: 14px;
  text-transform: capitalize;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

button.tag-button:hover {
  cursor: pointer;
}

img.search-icon:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0;
  }
}
</style>