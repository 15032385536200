<template>
  <section class="container">
    <img v-if="post.filename" :src="post.src" />
    <div class="content">
      <div class="post-header">
        <div class="post-info">
          <img src="@/assets/icons/my_profile.svg" alt="author" />
          <span>{{ post.author }}</span>
        </div>
        <div class="post-info">
          <img src="@/assets/icons/calendar.svg" alt="author" />
          <span>{{ post.posted_at }}</span>
        </div>
      </div>
      <h2>{{ post.title }}</h2>
      <p class="post-synopsis">{{ preview }}</p>
      <router-link class="post-link" :to="`/blog-post/${post.id}`">
        Continuar Lendo
      </router-link>
    </div>
  </section>
</template>


<script>
export default {
  name: "BlogPostPreview",
  props: ["post"],
  computed: {
    preview: function () {
      return this.post.content
        .replace(/(<([^>]+)>)/gi, " ")
        .replace(/(&nbsp;)/g, "")
        .substring(0, 500)
        .replace(/((\s*\S+)*)\s*/, "$1")
        .concat("...");
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin-bottom: 2rem;
}

.container img {
  max-height: 350px;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border: 2px solid #f3f3f3;
  border-radius: 5px;
}

.post-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  font-weight: bold;
}

.post-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.post-info img {
  margin-right: 0.5rem;
  filter: brightness(0) saturate(100%) invert(80%) sepia(18%) saturate(5081%)
    hue-rotate(351deg) brightness(99%) contrast(96%);
}

.post-synopsis {
  margin: 1.5rem 0 2rem 0;
}

.post-link {
  background: transparent;
  color: #f9b010;
  font-weight: bold;
}
</style>